<template>
  <div>
    <slot v-bind="{ openModal }">
      <v-card
        v-bind="$attrs"
        outlined
        rounded="lg"
        tag="button"
        type="button"
        :class="[
          'project-card-new',
          'cy-card cy-card--dashed-border cy-card--interactive',
          buttonClass,
        ]"
        @click="openModal">
        <v-card-text>
          <v-row
            dense
            align="center"
            justify="start">
            <v-col cols="auto">
              <v-icon size="xx-large">
                mdi-plus
              </v-icon>
            </v-col>
            <v-col class="text-left">
              <span class="project-card-new__label mr-3">{{ $t('addFavoriteProject') }}</span>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </slot>

    <CyModal
      v-if="isModalVisible"
      :header-title="$t('addFavoriteProject')"
      :loading="isSaving"
      :action-btn-func="submit"
      :action-btn-disabled="!canSave"
      :action-btn-text="$t('addFavoriteProject')"
      action-btn-icon="mdi-plus"
      :cancel-btn-func="close"
      :close-modal-on-action-click="false"
      modal-type="create">
      {{ $t('selectProject') }}
      <v-form
        class="mt-6"
        @submit.prevent>
        <CyAlert
          theme="error"
          :content="errors"/>
        <v-autocomplete
          ref="memberInput"
          v-model="$v.project.$model"
          :items="nonFavoriteProjects"
          :loading="loadingProjects"
          :error-messages="projectErrors"
          :label="$t('Project')"
          :filter="filter"
          item-text="name"
          item-value="canonical"
          return-object
          required
          autofocus
          class="required-field"/>
      </v-form>
      <CyAlert
        class="mt-4"
        :title="$t('didYouKnow')"
        :content="$t('howToAdd')"/>
    </CyModal>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'CyAddFavoriteProject',
  inheritAttrs: false,
  props: {
    buttonClass: {
      type: String,
      default: '',
    },
  },
  validations: {
    project: { required },
  },
  data: () => ({
    project: null,
    isModalVisible: false,
    isSaving: false,
    nonFavoriteProjects: [],
  }),
  computed: {
    ...mapState('organization/project', {
      errors: (state) => state.errors.favorite,
    }),
    ...mapState('organization', {
      loadingProjects: (state) => state.fetchInProgress.projects,
      projects: (state) => state.available.projects,
    }),
    canSave () {
      return !this.$v.$invalid && !this.isSaving
    },
    projectErrors () {
      const errors = []
      const { $dirty, required } = this.$v.project
      if (!$dirty) return []
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      return errors
    },
  },
  methods: {
    ...mapActions('organization', [
      'FETCH_AVAILABLE',
    ]),
    ...mapActions('organization/project', [
      'SET_FAVORITE_PROJECT',
    ]),
    ...mapMutations('organization/project', [
      'CLEAR_PROJ_ERRORS',
    ]),
    async openModal () {
      this.$setOriginalData()
      await this.FETCH_AVAILABLE({
        keyPath: 'projects',
        extraParams: [{ favorite: false }],
      })
      this.nonFavoriteProjects = _.cloneDeep(this.projects)
      this.isModalVisible = true
    },
    async submit () {
      if (!this.canSave) return
      this.$emit('save')
      this.$toggle.isSaving(true)
      await this.SET_FAVORITE_PROJECT(this.project)
      if (_.isEmpty(this.errors)) {
        this.$emit('favorited', this.project)
        this.close()
      }
      this.$toggle.isSaving(false)
    },
    close () {
      this.isModalVisible = false
      this.$resetData()
      this.$v.$reset()
      this.CLEAR_PROJ_ERRORS('favorite')
    },
    filter ({ name, canonical }, queryText) {
      return _.isEmpty(queryText)
        ? true
        : _.some([
          name.toLowerCase().includes(queryText.toLowerCase()),
          canonical.toLowerCase().includes(queryText.toLowerCase()),
        ])
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.dashboard',
        addFavoriteProject: 'Add favorite project',
        selectProject: 'Select a project from the dropdown below and add it to your favorites.',
        didYouKnow: 'Did you know?',
        howToAdd: 'You can add projects to your favorites from the project list, by clicking on the star icon.',
      },
      es: {
        title: '@:routes.dashboard',
        addFavoriteProject: 'Agregar proyecto favorito',
        selectProject: 'Seleccione un proyecto del dropdown de abajo y agreguelo a sus favoritos',
        didYouKnow: 'Sabía que?',
        howToAdd: 'Puede añadir proyectos de la lista a sus favoritos, cliqueando en el icono  de la estrella',
      },
      fr: {
        title: '@:routes.dashboard',
        addFavoriteProject: 'Ajouter un projet favori',
        selectProject: 'Sélectionnez un project dans la liste déroulante et ajoutez-le à vos favoris.',
        didYouKnow: 'Le saviez-vous ?',
        howToAdd: `Vous pouvez ajouter des projets à vos favoris depuis la liste des projets, en cliquant sur l'icône étoile`,
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.project-card-new {
  background-color: transparent;

  &:hover {
    background-color: cy-get-color("primary", "light-5");
  }

  .v-icon {
    color: cy-get-color("primary", "light-3");
  }

  &__label {
    color: cy-get-color("primary", "light-2");
    font-weight: $font-weight-bolder;
    line-height: $line-height-default;
  }
}
</style>
