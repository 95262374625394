<template>
  <component
    :kpi="{
      ...kpi,
      envs,
    }"
    v-on="$listeners"
    v-bind="$attrs"
    :is="getWidgetComponent(kpi)">
    <template #actions>
      <div class="widget__actions">
        <CyMenu
          bottom
          left
          offset-y>
          <template #activator="{ on }">
            <CyButton
              variant="tertiary"
              theme="primary"
              icon="more_horiz"
              icon-only
              sm
              v-on="on"/>
          </template>
          <v-list>
            <v-list-item
              class="font-weight-normal"
              :to="{
                name: 'project',
                params: {
                  orgCanonical,
                  projectCanonical: kpi.project_canonical,
                },
              }">
              <v-list-item-action>
                <v-icon>
                  arrow_forward
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ $t('goToProject') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              :disabled="!canEdit || isSaving"
              @click.stop="unsetFavorite">
              <v-list-item-action>
                <v-icon>
                  star_outline
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ $t('unfavorite') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </CyMenu>
      </div>
    </template>
    <template #footer>
      <router-link
        class="cy-headline"
        :to="{
          name: 'project',
          params: {
            orgCanonical,
            projectCanonical: kpi.project_canonical,
          },
        }">
        {{ kpi.project_canonical }}
      </router-link>
    </template>
  </component>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import CyKpiWidgetsBuildFrequency from '@/components/kpi-widgets/build-frequency.vue'
import CyKpiWidgetsBuildHistory from '@/components/kpi-widgets/build-history.vue'
import CyKpiWidgetsBuildTime from '@/components/kpi-widgets/build-time.vue'
import CyKpiWidgetsCodeCoverage from '@/components/kpi-widgets/code-coverage.vue'
import CyKpiWidgetsSuccessFailure from '@/components/kpi-widgets/success-failure.vue'
import CyKpiWidgetsTimeToRelease from '@/components/kpi-widgets/time-to-release.vue'
import { getWidgetComponent } from '@/utils/helpers'

export default {
  name: 'CyKpiWidgetsDashboardWidget',
  components: {
    CyKpiWidgetsCodeCoverage,
    CyKpiWidgetsTimeToRelease,
    CyKpiWidgetsSuccessFailure,
    CyKpiWidgetsBuildHistory,
    CyKpiWidgetsBuildTime,
    CyKpiWidgetsBuildFrequency,
  },
  props: {
    kpi: {
      type: Object,
      required: true,
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    isSaving: false,
    envs: null,
  }),
  computed: {
    ...mapState({
      errors: (state) => state.organization.project.kpi.errors.favorite,
    }),
  },
  async created () {
    const { project_canonical: projectCanonical } = this.kpi
    const { data } = await this.$cycloid.ydAPI.getProject(this.orgCanonical, projectCanonical) || {}
    this.$set(this, 'envs', data?.environments ?? [])
  },
  methods: {
    ...mapActions('alerts', [
      'SHOW_ALERT',
    ]),
    ...mapActions('organization/project/kpi', [
      'UNSET_FAVORITE_KPI',
    ]),
    async unsetFavorite () {
      this.$emit('unfavorite')
      this.$toggle.isSaving(true)
      await this.UNSET_FAVORITE_KPI(this.kpi)
      this.$toggle.isSaving(false)
      if (_.isEmpty(this.errors)) this.$emit('unfavorited')
      else this.SHOW_ALERT({ type: 'error', content: this.errors })
    },
    getWidgetComponent,
  },
  i18n: {
    messages: {
      en: {
        unfavorite: 'Remove from favorites',
        goToProject: `Go to metric's project`,
      },
      es: {
        unfavorite: 'Eliminar de favoritos',
        goToProject: 'Acceder a las métricas del proyecto',
      },
      fr: {
        unfavorite: 'Retirer des favoris',
        goToProject: `Accéder au projet de la métrique`,
      },
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .widget {
  border-color: cy-get-color("primary", "light-4");

  &__footer {
    color: cy-get-color("primary", "light-3");
    font-size: $font-size-sm;

    a:not(:hover) {
      color: inherit;
    }
  }
}
</style>
