import { render, staticRenderFns } from "./emissions-overview.vue?vue&type=template&id=6f594bea&scoped=true"
import script from "./emissions-overview.vue?vue&type=script&lang=js"
export * from "./emissions-overview.vue?vue&type=script&lang=js"
import style0 from "./emissions-overview.vue?vue&type=style&index=0&id=6f594bea&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f594bea",
  null
  
)

export default component.exports