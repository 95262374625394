var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{class:['project-card cy-card cy-card--interactive', {
    'project-card--active': _vm.isMenuOpen,
    'cy-card--active': _vm.isMenuOpen,
  }],attrs:{"to":{
    name: 'project',
    params: {
      orgCanonical: _vm.orgCanonical,
      projectCanonical: _vm.project.canonical,
    },
  },"outlined":"","rounded":"lg"}},[_c('v-card-text',[_c('v-row',{staticClass:"flex-nowrap",attrs:{"dense":"","no-gutters":"","align":"center","justify":"start"}},[_c('v-col',{staticClass:"pr-3 py-0",attrs:{"cols":"auto"}},[_c('v-avatar',{attrs:{"size":"24","rounded":""}},[_c('img',{attrs:{"src":_vm._.get(_vm.project, 'service_catalog.image', '/static/images/stack_default.png'),"alt":_vm.project.name}})])],1),_c('v-col',{staticClass:"text-left py-0 overflow-x-hidden"},[_c('div',{staticClass:"project-card__name line-height-sm font-weight-bold text-truncate"},[_vm._v(" "+_vm._s(_vm.project.name)+" ")]),_c('div',{staticClass:"project-card__updated-at line-height-md text-truncate"},[_vm._v(" "+_vm._s(_vm.$t('updated', [_vm.$date.$formatTimeAgo(_vm.project.updated_at)]))+" ")])]),_c('v-col',{staticClass:"pl-3 py-0",attrs:{"cols":"auto"}},[_c('CyMenu',{attrs:{"bottom":"","left":"","offset-y":""},on:{"input":function (isOpen) { _vm.isMenuOpen = isOpen }},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('CyButton',_vm._g({attrs:{"variant":"tertiary","theme":"primary","icon":"more_horiz","icon-only":"","sm":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();}}},on))]}}])},[_c('v-list',[_c('v-list-item',{staticClass:"font-weight-normal",attrs:{"to":{
                name: 'project',
                params: {
                  orgCanonical: _vm.orgCanonical,
                  projectCanonical: _vm.project.canonical,
                },
              }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(" arrow_forward ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('goToProject')))])],1)],1),_c('v-list-item',{attrs:{"disabled":!_vm.canEdit || _vm.isSaving},on:{"click":function($event){$event.stopPropagation();return _vm.unsetFavoriteProject()}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(" star_outline ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('unfavorite')))])],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }