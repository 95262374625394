var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.getWidgetComponent(_vm.kpi),_vm._g(_vm._b({tag:"component",attrs:{"kpi":Object.assign({}, _vm.kpi,
    {envs: _vm.envs})},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('div',{staticClass:"widget__actions"},[_c('CyMenu',{attrs:{"bottom":"","left":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('CyButton',_vm._g({attrs:{"variant":"tertiary","theme":"primary","icon":"more_horiz","icon-only":"","sm":""}},on))]}}])},[_c('v-list',[_c('v-list-item',{staticClass:"font-weight-normal",attrs:{"to":{
              name: 'project',
              params: {
                orgCanonical: _vm.orgCanonical,
                projectCanonical: _vm.kpi.project_canonical,
              },
            }}},[_c('v-list-item-action',[_c('v-icon',[_vm._v(" arrow_forward ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('goToProject')))])],1)],1),_c('v-list-item',{attrs:{"disabled":!_vm.canEdit || _vm.isSaving},on:{"click":function($event){$event.stopPropagation();return _vm.unsetFavorite.apply(null, arguments)}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v(" star_outline ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('unfavorite')))])],1)],1)],1)],1)],1)]},proxy:true},{key:"footer",fn:function(){return [_c('router-link',{staticClass:"cy-headline",attrs:{"to":{
        name: 'project',
        params: {
          orgCanonical: _vm.orgCanonical,
          projectCanonical: _vm.kpi.project_canonical,
        },
      }}},[_vm._v(" "+_vm._s(_vm.kpi.project_canonical)+" ")])]},proxy:true}])},'component',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }