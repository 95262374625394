<template>
  <v-card
    :to="{
      name: 'project',
      params: {
        orgCanonical,
        projectCanonical: project.canonical,
      },
    }"
    outlined
    rounded="lg"
    :class="['project-card cy-card cy-card--interactive', {
      'project-card--active': isMenuOpen,
      'cy-card--active': isMenuOpen,
    }]">
    <v-card-text>
      <v-row
        dense
        no-gutters
        align="center"
        justify="start"
        class="flex-nowrap">
        <v-col
          cols="auto"
          class="pr-3 py-0">
          <v-avatar
            size="24"
            rounded>
            <img
              :src="_.get(project, 'service_catalog.image', '/static/images/stack_default.png')"
              :alt="project.name">
          </v-avatar>
        </v-col>
        <v-col class="text-left py-0 overflow-x-hidden">
          <div class="project-card__name line-height-sm font-weight-bold text-truncate">
            {{ project.name }}
          </div>
          <div
            class="project-card__updated-at line-height-md text-truncate">
            {{ $t('updated', [$date.$formatTimeAgo(project.updated_at)]) }}
          </div>
        </v-col>
        <v-col
          cols="auto"
          class="pl-3 py-0">
          <CyMenu
            bottom
            left
            offset-y
            @input="(isOpen) => { isMenuOpen = isOpen }">
            <template #activator="{ on }">
              <CyButton
                variant="tertiary"
                theme="primary"
                icon="more_horiz"
                icon-only
                sm
                v-on="on"
                @click.stop.prevent/>
            </template>
            <v-list>
              <v-list-item
                class="font-weight-normal"
                :to="{
                  name: 'project',
                  params: {
                    orgCanonical,
                    projectCanonical: project.canonical,
                  },
                }">
                <v-list-item-icon>
                  <v-icon>
                    arrow_forward
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('goToProject') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                :disabled="!canEdit || isSaving"
                @click.stop="unsetFavoriteProject()">
                <v-list-item-icon>
                  <v-icon>
                    star_outline
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('unfavorite') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </CyMenu>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'CyProjectMiniCard',
  props: {
    project: {
      type: Object,
      required: true,
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    isMenuOpen: false,
    isSaving: false,
  }),
  computed: {
    ...mapState('organization/project', {
      errors: (state) => state.errors,
    }),
  },
  methods: {
    ...mapActions('alerts', [
      'SHOW_ALERT',
    ]),
    ...mapActions('organization/project', [
      'UNSET_FAVORITE_PROJECT',
    ]),
    async unsetFavoriteProject () {
      this.$emit('unfavorite')
      this.$toggle.isSaving(true)
      await this.UNSET_FAVORITE_PROJECT(this.project)
      this.$toggle.isSaving(false)
      if (_.isEmpty(this.errors.favorite)) this.$emit('unfavorited')
      else this.SHOW_ALERT({ type: 'error', content: this.errors.favorite })
    },
  },
  i18n: {
    messages: {
      en: {
        goToProject: 'Go to project',
        unfavorite: 'Remove from favorites',
        updated: 'updated {0}',
      },
      es: {
        goToProject: 'Ir al proyecto',
        unfavorite: 'Eliminar de favoritos',
        updated: 'actualizado {0}',
      },
      fr: {
        goToProject: 'Aller au projet',
        unfavorite: 'Retirer des favoris',
        updated: 'mis à jour {0}',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.project-card {
  ::v-deep .cy-btn {
    display: none;
  }

  &--active,
  &:hover {
    ::v-deep .cy-btn {
      display: block;
    }
  }

  &__name {
    color: cy-get-color("primary");
  }

  &__updated-at {
    color: cy-get-color("primary", "light-3");
    font-size: $font-size-sm;
  }
}
</style>
